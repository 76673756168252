.Header {
    position: fixed;
    top: 3%;
    left: 50%;
    transform: translate(-50%, -3%);
    /* Stick it to the top */
    /* max-height: 120px; */
    width: 90%;
    z-index: 1;
    display: grid;
    grid-template-areas: "logo nav";

    /* Cosmetics */
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* border-radius: 5%; */
    padding: 10px 20px;
    /* background-color: red; */
}

.Logo {
    grid-area: logo;
    height: 100px;
    /* width: 100%;
    height: 40%; */
    margin-left: 30px;
}

.Nav {
    display: grid;
    grid-area: nav;
    grid-template-columns: repeat(5, auto);
    grid-row-gap: 10px;

    align-items: center;
    justify-items: center;
}

.Nav a {
    color: #072A52;
    font-size: 18px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
}

.Nav button {
    padding: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #072A52;
    font-weight: 600;
    background-color: transparent;
    /* box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25); */
    /* border-radius: 5px; */
    cursor: pointer;
    transition: 70ms;
}

.Nav button:hover {
    border-bottom: 2px solid #F6931E;
}

.Nav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
}

.Burger:active {
    transform: scale(1.2);
}

@media (max-width: 700px) {
    .Header {
        grid-template-areas: "logo burger""nav nav";
    }

    .Nav {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: none;
        grid-row-gap: 20px;

        padding: 30px 0 30px;
        background: rgba(40, 44, 47, 0.95);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .Nav a {
        color: white;
        font-size: 18px;
        font-weight: 500;
        transition: 0.5s;
        text-decoration: none;
    }

    .Burger {
        display: inline;
    }
}

.NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
}

.NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
}

.NavAnimation-exit {
    opacity: 1;
}

.NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
}