.videosHolder{
    /* border: 1px solid red; */
    width : 80%;
    /* height : 100vh; */
    margin : 0 auto
}
.subVideoHolder{
    /* border: 1px solid green; */
    height: 60vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-color:  #999 #f1f1f1;
    background-image: url(../..//Assets/Companybg.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.GradeBtn{
    width : 30%;
    margin: 0 auto;
    background-color: #072A52;
    border: none;
    font-weight: 900;
    letter-spacing: 1px;
}

::-webkit-scrollbar {
    transition-duration: 0.3s;
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:hsla(100,0%,0%,0.2); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }