.dashboardContainer{
    background-image: url(../Assets/Companybg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}
.userNavigation{
    background-color: white;;
    box-shadow: 0 4px 6px -6px #222;
}
.userNavigation #myBrand{
    color: #072A52;
    font-weight: 900;
    /* letter-spacing: 9px; */
    font-size: 36px;
}
.userNavigation #logout_btn{
    border-radius: 5px;
    color: #072A52;
    font-weight: 600;
    background-color: #FFC300;
}
.userNavigation #welcomeMsg{
    border: 1px solid #072A52;
    margin-right: 5px;
}
#welcomeMsg{
    color: #072A52;
    font-weight: 600;
}
#welcomeMsg span{
    color: #FFC300;
    text-decoration: underline;
}
.AskQuestion_Column{
    /* border: 1px solid red; */
    margin: 0 auto
}
.AskQuestion_Column h1{
    font-weight: 900;
    text-align: center;
    font-size: 32px;
    color: #072A52;
}
.AskQuestion_Column h1 span{
    color: #FFC300;
}
.cardContainer{
    margin: 5% auto;
}
.cardContainer .card{
    border-radius: 15px;
    background-color: none;
    background-image: url(../Assets//Companybg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}
.cardContainer .cardHeader{
    background-color: #072A52;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.cardContainer .request_btn{
    background-color: #FFC300;
    border-radius: 15px;
    color: #072A52;
    font-weight: 600;
}
.cardContainer .request_btn:hover{
    background-color: white;
    border: 2px solid #FFC300;
}
@media screen and (max-width: 900px) {
    .userNavigation #myBrand{
        font-size: 28px;
    }
    .userNavigation #welcomeMsg{
        border: none;
        margin-right: 0px;
    }

}