.navigation{
    background-color: white;
    box-shadow: 0 4px 6px -6px #222;
    height : 100px
}
.logo_brand {
    border : 1px solid red;
    width : 100%;
    position: absolute;
}
#brand{
    color: #072A52;
    font-weight: 900;
    /* letter-spacing: 9px; */
    font-size: 36px;
}
#collasible-nav-dropdown{
    background-color: #FFC300;
    border-radius: 5px;
    color: #072A52;

    font-weight: 600;
}
#collasible-nav-dropdown:hover{
    background-color: #ffffff;
    border: 1px solid #FFC300;
    color: #072A52
}
#nav-subdropdown{
    color: #072A52;
    font-weight: 500;
}
#nav-subdropdown:hover{
    /* background-color: #146eb3; */
    
    text-decoration: none;
    font-weight: 500;
}
#CreateAcc_btn{
    border: 1px solid #072A52;
    color: #072A52;
    font-weight: 600;
    background: linear-gradient(to right, #072A52 50% , white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    margin-right: 1em;
}
#CreateAcc_btn:hover{
    color: white;
    font-weight: 500;
    background-position: left bottom;  
}
/* Signup */
.signupDiv{
    background-image: url(../Assets//Companybg.svg);
    background-size: cover;
}
.signupDiv h2{
    font-weight: 600;
    letter-spacing: 1px;
    color: #072A52
}
.signupDiv h2 span{
    color: #FFC300
}
.signupDiv .createAcc_btn{
    background-color: #072A52
}
/* login */
.loginDiv{
    background-image: url(../Assets//Companybg.svg);
    background-size: cover;
}
.loginDiv h2{
    font-weight: 600;
    letter-spacing: 1px;
    color: #072A52
}
.loginDiv h2 span{
    color: #FFC300
}
.loginDiv .login_btn{
    background-color: #072A52
}
/* Slider */
#Carosel{
    position: relative;
    min-height: 80vh;
}
#Caption{
    position: absolute;
    top: 25%;
    background-color: #072a526f;
    border-radius: 15px;
    /* height: 200px; */
}
#Caption h3{
    /* color: #146eb3; */
    color: white;
    font-weight: 900;
    font-size: 48px;
}
#Caption p{
    color: white;
    font-weight: 700;
    font-size: 23px;
}
#watchVideo_btn{
    /* border: 1px solid #146eb3;
    color: #146eb3; */
    border-radius: 15px;
    /* border: 5px solid white; */
    background-color: #072A52;
    color: white;
    font-size: 100%;
    margin : 1px;
}
#watchVideo_btn:hover{
    background-color: #0b3b73;
    color : #FFC300;
}

/* #watchVideo_btn:hover{
    background-color: white;
    color: #146eb3;
    font-weight: bold;
    font-size: 100%;
    text-transform: uppercase;
}  */
/* About */
.About_container{
    background-color: #f4f4f4;
    padding-bottom: 5%;
}
#card1, #card2, #card3{
    margin : 0 auto;
    border: none;
    background-color: #f4f4f4;
}
#card1 i, #card2 i, #card3 i{
    color: #FFC300;
    font-size: 36px;
}
#card1 i:hover, #card2 i:hover, #card3 i:hover{
    transform: rotate(70deg);
    transition-duration: 1s;
}
#card1 #title, #card2 #title, #card3 #title{
    color: #072A52;
    text-transform: capitalize;
    font-weight: 900;
    /* font-size: 26px; */
    /* letter-spacing: 1px; */
}
#card1 #descrip, #card2 #descrip, #card3 #descrip{
    text-align: justify;
}
.Aboutsection_heading{
    text-align: center;
    padding-top: 5%;
    /* border: 1px solid red; */
}
.Aboutsection_heading h6{
    color: black;
    font-weight: 900;
    font-size: 32px;
}
.Aboutsection_heading p{
    color: black;
    font-weight: 200;
    letter-spacing: 1px;
}
/* Ebooks */
.sectionsHolder{
    background-image: url(../Assets/Companybg.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.ebooks_pros{
    /* border: 1px solid red; */
    margin: 20% auto;
}
.ebooks_pros h6{
    color: #072A52;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: 1px;

}
.ebooks_pros p{
    font-size: 20px;
    letter-spacing: 1px;
}
.ebooks_pros p i{
    color: #FFC300;
    font-size: 20px;
}
/* Tution */
.tution_pros{
    /* border: 1px solid red; */
    margin: 20% auto;
    padding-left: 20%;
}
.tution_pros h6{
    color: #072A52;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: 1px;

}
.tution_pros p{
    font-size: 20px;
    letter-spacing: 1px;
}
.tution_pros p i{
    color: #FFC300;
    font-size: 20px;
}


/* Video Lectures */
.videoLectures_pros{
    /* border: 1px solid red; */
    margin: 20% auto;
    padding-left: 20%;
}
.videoLectures_pros h6{
    color: #072A52;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: 1px;

}
.videoLectures_pros p{
    font-size: 20px;
    letter-spacing: 1px;
}
.videoLectures_pros p i{
    color: #FFC300;
    font-size: 20px;
}

/* Testimonial */
/* .TestimonialContainer{
    background-color: #072A52;
} */
.Testimonial_column{
    text-align: center;
}
.testimonial_Row{
    padding-bottom: 5%;
}
.Testimonial_heading{
    text-align: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /* border: 1px solid red; */
}
.Testimonial_heading h6{
    color: #072A52;
    font-weight: 900;
    font-size: 32px;
}
.Testimonial_heading hr{
    border: 5px solid #072A52;
    background-color: #072A52;
    width: 20%;
    margin: 2% auto;
    border-radius: 15px;
}
figure.snip1192 {
    font-family: 'Raleway', Arial, sans-serif;
    margin: 10px auto;
    position: relative;
    overflow: hidden;
    /* margin: 10px; */
    min-width: 220px;
    max-width: 310px;
    width: 100%;
    color: #333;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    /* box-shadow: none !important; */
  }
  figure.snip1192 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  figure.snip1192 img {
    max-width: 100%;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
    display: inline-block;
    z-index: 1;
    position: relative;
  }
  figure.snip1192 blockquote {
    margin: 0;
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: white;
    padding: 30px 50px 65px 50px;
    font-size: 0.8em;
    font-weight: 500;
    margin: 0 0 -50px;
    line-height: 1.6em;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* box-shadow: 5px 10px 5px 10px rgba(0, 0, 0, 0.15); */
  }
  figure.snip1192 blockquote:before,
  figure.snip1192 blockquote:after {
    font-family: 'FontAwesome';
    content: "\201C";
    position: absolute;
    font-size: 50px;
    opacity: 0.3;
    font-style: normal;
  }
  figure.snip1192 blockquote:before {
    top: 35px;
    left: 20px;
  }
  figure.snip1192 blockquote:after {
    content: "\201D";
    right: 20px;
    bottom: 35px;
  }
  figure.snip1192 .author {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    color: #072A52;
  }
  figure.snip1192 .author h5 {
    opacity: 0.8;
    margin: 0;
    font-weight: 800;
  }
  figure.snip1192 .author h5 span {
    font-weight: 700;
    font-size: 12px;
    text-transform: capitalize;
    display: block;
  }
  
/* Collaboration Section  */
.Collaboration_heading{
    text-align: center;
    /* padding-top: -1%; */
    /* padding-bottom: 1%; */
    /* border: 1px solid red; */
}
.Collaboration_heading .Collaborate_btn{
    /* background-color: #072A52; */
    font-size: 18px;
    color: white;
    font-weight: 700;
    /* text-decoration: underline; */
    border: none;
    background-color: #072A52;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Collaboration_heading .Collaborate_btn span{
    color:#FFC300
}
.Collaboration_heading .Collaborate_btn:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 10px 6px 18px;

}
.Collaboration_images{
    text-align: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /* border: 1px solid red; */
}
.Collaboration_heading h6{
    color: #072A52;
    font-weight: 900;
    font-size: 32px;
}
.Collaboration_heading hr{
    border: 5px solid #072A52;
    width: 20%;
    margin: 2% auto;
    border-radius: 15px;
}
.Collaboration_column .Collaboration_heading{
    /* border: 1px solid brown; */
    /* min-height: 60vh; */
    padding-top: 20%;
}
.Collaboration_column2 .Collaboration_images{
    /* border: 1px solid brown; */
    display: flex;
    justify-content: space-around;
    /* min-height: 60vh; */
    margin: 0px;
    padding-top: 5%;
}
.Collaboration_column2 .Collaboration_images .images{
    /* border: 1px solid green; */
    width : 25%
}
.Collaboration_column2 .Collaboration_images .images img{
    width : 100%
}
/* Position Holders */
.PositionHolderContainer{
    background-image: url(../Assets/Companybg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    /* transform: rotate(30deg); */
}
.Position_heading{
    text-align: center;
    padding-top: 5%;
    padding-bottom: 2%;
    /* border: 1px solid red; */
}
.Position_heading h6{
    color: #072A52;
    font-weight: 900;
    font-size: 32px;
}
.Position_heading hr{
    border: 5px solid #072A52;
    width: 20%;
    margin: 2% auto;
    border-radius: 15px;
}
.PositionHolder_Images_Column{
    /* border: 1px solid red; */
    /* width: 100%; */
    /* minheight: 60vh; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    /* min-width: 50%; */
    height: 60vh;
    background-image: url(../Assets//Companybg.svg);
    background-size: cover;
    padding: 1%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin : 0 auto;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-color:  #999 #f1f1f1;
}
.containerStudent {
    margin: 1%;
    position: relative;
    width: 25%;
    border-radius: 15px;
}
  
.containerStudent .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    border-radius: 15px;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
.containerStudent .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .containerStudent:hover .image {
    opacity: 0.3;
  }
  
  .containerStudent:hover .middle {
    opacity: 1;
  }
  
  .containerStudent .text {
    /* background-color: #072A52; */
    color: #072A52;
    border-radius: 15px;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 11px;
    /* width: 100%;
    z-index: 1000; */
  }

/* Donate */
.DonateContainer{
    margin-top: 10%;
    margin-bottom: 10%;
    min-height: 40vh;
}
.DonateContainer .DonateColumn1{
    margin: 0 auto;
}
.DonateContainer .DonateColumn1 .Donate_btn{
    width: 100%;
    background-color: #072A52;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 26px;
    border-radius: 15px;
    border: none;
    margin-bottom: 5%;
}
.DonateContainer .DonateColumn1 .crossbutton i:hover{
    transform: rotate(60deg);
    transition-duration: 1s;
}
/* GetInTouch */
.footerNav{
    /* background-color: #072A52; */
    border-top: 5px solid #FFC300;
    position: relative;
}
.footerNav #brand{
    color: white;
    font-weight: 900;
    /* letter-spacing: 9px; */
    font-size: 36px;
    text-transform: uppercase;
}
.social{
    color: white;
    font-weight: 900;
    font-size: 26px;
    /* border: 2px solid white; */
}
.footerNav .JoinNowText{
    border: 1px solid white;
    width : 40%;
    height: 10vh;
    background-color: white;
    /* line-height: 10vh; */
    border-radius: 45px;
    text-align: center;
    position: absolute;
    top : -40%;
    left : 30%;
    /* border: 1px solid #222; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.footerNav .JoinNowText .joinNow-heading{
    color: #072A52;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: 1px;
}
.footerNav .JoinNowText .joinNow-heading span{
    color:#FFC300
}


.footerNavContactForm .formHeading{
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 1px;
}
.footerNavContactForm .inputs{
    /* border : 2px solid red; */
    border-radius: 25px;
    padding: 10px;
    border : none
}
.footerNavContactForm .submitButton{
    background-color: white;
    color: #146eb3;
    /* font-weight: 500; */
    text-transform: capitalize;
    border-radius: 25px;
    border: none;
    width: 20%;
    padding: 10px;
}
.footerNavContactForm .submitButton:hover{
    background-color: #072A52;
    border: 2px solid white;
    color: white
}
.subscribeWithMail{
    /* border : 1px solid red; */
    border-radius: 25px;
    position: relative;
}
.subscribeWithMail .SubscribeInput{
    border-radius: 25px;
    padding: 10px;
}
.subscribeWithMail .SubecribeButton{
    position: absolute;
    top: 0px;
    right : 0px;
    border-radius: 25px;
    padding: 10px;
    background-color: #FFC300;
    width: 40%;
    margin: 1px;
    border: none;
    color: #072A52;
    text-transform: uppercase;
}
.subscribeWithMail .SubecribeButton:hover{
    background-color: white;
    border: 1px solid #072A52;
    margin: 1px;
    color: #072A52
}
.footerNavContactForm .list{
    /* border: 1px solid red; */
    height: 200px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.footerNavContactForm .list div{
    /* border: 1px solid red; */
    /* background-color: #01aa95; */
    width: 40%;
}
.footerNavContactForm .list div ul{
    list-style-type: none;
    color: white;
    font-weight: 200;
}
.footerNavContactForm .list div ul li h6{
    font-weight: 800;
}
/* Company About */
.companyAbout{
    background-image: url(../Assets/Companybg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 150px;
}
.companyAbout h1{
    font-weight: 900;
    color: #072A52;
    letter-spacing: 1px;
    font-size: 36px;
    margin-bottom: 30px;
}
.companyAbout h1 span{
    color: #FFC300
}
.companyAbout p{
    text-align: justify;
}


/* 24/7 Contact form */
.ContactPage{
    background-image: url(../Assets/Companybg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 150px;
}
.twentyFourContact h1{
    font-weight: 900;
    color: #FFC300;
    letter-spacing: 1px;
    font-size: 36px;
    margin-bottom: 30px;
    text-align:center;
}
.twentyFourContact h1 span{
    color : #072A52
}
.twentyFourContact p{
    color: #ADB5BD;
    text-align: center;
}
.twentyFourContact .submitMsg{
    background-color: #072A52;
    border-radius: 25px;
    width: 30%;
    padding: 10px;
}
.twentyFourContact .inputs{
    border-radius: 25px;
    padding: 10px;
    /* border : none */
}

.RateusModal{
    background-image: url(../Assets/Companybg.svg);
    background-size: cover;
}
.RateusHeader h1{
    font-size: 32px;
    font-weight: 700;
    color: #072A52;
}
.RateusHeader h1 span{
    color: #FFC300;
}

.RateusModal .submit{
    background-color: #072A52;
    border: 1px solid #072A52;
    border-radius: 15px;
}

@media screen and (max-width: 900px){
    .footerNav #brand{
        margin: 0 auto;
    }
    .footerNav .socialIconsHolder{
        margin : 0 auto
    }
    .footerNavContactForm .formHeading{
        /* border : 1px solid red; */
        text-align: center;
    }
    .footerNavContactForm .list div{
        /* border: 1px solid red; */
        margin: 0 auto;
        text-align: center;
    }
    .footerNav .JoinNowText{
        visibility: hidden;
    }
}

@media screen and (max-width: 591px) {
    #brand{
        font-size: 26px;
    }
    #CreateAcc_btn{
        border: 1px solid #01aa95;
        color: #01AA95;
        background: linear-gradient(to right, #01aa95 50% , white 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all .5s ease-out;
        margin-left: 30%;
        margin-bottom: 5%;
        /* margin : 0 auto */
    }
    #CreateAcc_btn:hover{
        color: white;
        font-weight: 500;
        background-position: left bottom;  
    }
    #Caption{
        top: 3vh
    }
    #Caption h3 {
      font-size: 80%;
    }
    #Caption p{
        font-size: 50%;
    }
    #watchVideo_btn{
        font-size: 60%;
    }
    .ebooks_pros{
        /* border: 1px solid red; */
        margin: 10% auto;
        text-align: center;
    }
    .tution_pros{
        /* border: 1px solid red; */
        margin: 10% auto;
        text-align: center;
        padding-left: 0;
    }
  
}


::-webkit-scrollbar {
    transition-duration: 0.3s;
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:hsla(100,0%,0%,0.2); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }